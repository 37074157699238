<template>
  <div class="pt-4 pb-2">
    <router-link
      to="/contacts"
      class="back-link ml-2"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      Contacts
    </router-link>

    <import-contacts-component />
  </div>
</template>

<script>
import ImportContactsComponent from '@/components/ImportContactsComponent'
export default {
  name: 'ImportContacts',
  components: {
    ImportContactsComponent
  },
  data: () => ({})
}
</script>
