<template>
  <div class="pt-4">
    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4 px-4 px-md-0">Queues</h1>
    <v-card class="mb-5">
      <v-list class="py-0">
        <template v-for="(item, itemIndex) in queues">
          <v-divider
            v-if="itemIndex"
            :key="itemIndex"
          ></v-divider>
          <queue-item :item="item" :key="itemIndex"/>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import QueueItem from '@/components/QueueItem'

export default {
  name: 'QueuesOverview',
  components: { QueueItem },
  data: () => ({
    queues: [
      {
        title: 'Marketing mailbox',
        users: [
          {
            name: 'Jessica Alba',
            phone: '169-851-8611',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Moses Malone',
            phone: '255-295-6559',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steph Curry',
            phone: '101-290-9975',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Jessica Alba',
            phone: '169-851-8611',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Moses Malone',
            phone: '255-295-6559',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steph Curry',
            phone: '101-290-9975',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Jessica Alba',
            phone: '169-851-8611',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Moses Malone',
            phone: '255-295-6559',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steph Curry',
            phone: '101-290-9975',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Jessica Alba',
            phone: '169-851-8611',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Moses Malone',
            phone: '255-295-6559',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steph Curry',
            phone: '101-290-9975',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Jessica Alba',
            phone: '169-851-8611',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Moses Malone',
            phone: '255-295-6559',
            lastYours: true,
            unreadMessage: true,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steph Curry',
            phone: '101-290-9975',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Jessica Alba',
            phone: '169-851-8611',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Moses Malone',
            phone: '255-295-6559',
            lastYours: true,
            unreadMessage: true,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          }
        ]
      },
      {
        title: 'Design mailbox',
        users: [
          {
            name: 'Rosa Ferguson',
            phone: '080-021-3014',
            lastYours: true,
            unreadMessage: true,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steve Nash',
            phone: '195-654-4944',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steph Curry',
            phone: '101-290-9975',
            lastYours: true,
            unreadMessage: false,
            missedCall: true,
            avatar: 'https://picsum.photos/200'
          }
        ]
      },
      {
        title: 'CEO mailbox',
        users: [
          {
            name: 'Rosa Ferguson',
            phone: '080-021-3014',
            lastYours: true,
            unreadMessage: true,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steve Nash',
            phone: '195-654-4944',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steph Curry',
            phone: '101-290-9975',
            lastYours: true,
            unreadMessage: false,
            missedCall: true,
            avatar: 'https://picsum.photos/200'
          }
        ]
      },
      {
        title: 'Support mailbox',
        users: [
          {
            name: 'Rosa Ferguson',
            phone: '080-021-3014',
            lastYours: true,
            unreadMessage: true,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          },
          {
            name: 'Steve Nash',
            phone: '195-654-4944',
            lastYours: true,
            unreadMessage: false,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          }
        ]
      },
      {
        title: 'Design mailbox',
        users: [
          {
            name: 'Rosa Ferguson',
            phone: '080-021-3014',
            lastYours: true,
            unreadMessage: true,
            missedCall: false,
            avatar: 'https://picsum.photos/200'
          }
        ]
      }
    ]
  }),
  computed: {
    isUser () {
      return this.$store.state.auth.currentUser && this.$store.state.auth.currentUser.role === 'User'
    }
  },
  created () {
    if (this.isUser) {
      this.queues = this.queues.filter(e => {
        return e.title !== 'Ceo mailbox'
      })
    }
  }
}
</script>
