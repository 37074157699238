<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" class="">
        <v-row class="ma-0 gap-10">
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <label>Company</label>
            <v-autocomplete
              v-model="accountUuid"
              :items="companyList"
              :loading="loading"
              outlined
              item-text="accountName"
              item-value="accountUuid"
              solo
              class="muted--text"
              light
              dense
              hide-details
              color="muted"
              label="Company"
            />
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <label>Email</label>
            <v-text-field
              v-model="userEmail"
              outlined
              solo
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <div style="min-width: 130px; width: 100%">
              <label>From</label>
              <date-picker-solo :value="filter.startDate" @changeDate="changeStartDate"/>
            </div>
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pa-0">
            <div style="min-width: 130px; width: 100%">
              <label>To</label>
              <date-picker-solo :value="filter.endDate" @changeDate="changeEndDate"/>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="px-0 grey-button"
          outlined
          @click="exportCurrentView"
        >
          Export
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="itemsPerPage"
          item-key="id"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-divider/>
        <div v-if="total > itemsPerPage" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex flex-wrap h-max">
            <v-btn
              :class="{ 'grey-button--selected': page === 1 }"
              text
              tile
              min-width="56"
              @click="pagination(1)"
            >1
            </v-btn>
            <span v-if="page > 3">...</span>
            <template v-for="index in (countPage - 2)">
              <template v-if="index >= page - 2 && index <= page">
                <v-divider
                  :key="index + 'v-divider--page'"
                  vertical
                  class="pa-0"
                />
                <v-btn
                  :class="{'grey-button--selected': index + 1 === page}"
                  text
                  tile
                  min-width="56"
                  :key="index"
                  @click="pagination(index + 1)"
                >{{ index + 1 }}
                </v-btn>
              </template>
            </template>
            <span v-if="page < countPage - 2">...</span>
            <v-btn
              :class="{ 'grey-button--selected': page === countPage }"
              text
              tile
              min-width="56"
              @click="pagination(countPage)"
            >{{ countPage }}
            </v-btn>
          </div>

          <div class="ma-5 br-3 grey-button d-flex" style="min-width: 73px;">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <vue-snotify/>
  </div>
</template>

<script>
import DatePickerSolo from '@/components/DatePickerSolo'
import { mapActions } from 'vuex'
import moment from 'moment'
import exportToCsv from '@/utilities/exportCSV'

export default {
  name: 'Payment',
  components: {
    DatePickerSolo
  },
  data: () => ({
    loading: false,
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: 'Paid On',
        align: 'start',
        sortable: false,
        value: 'payOn',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Company Name',
        align: 'start',
        sortable: false,
        value: 'accountName',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Amount',
        align: 'start',
        sortable: false,
        value: 'amount',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Purpose',
        align: 'start',
        sortable: false,
        value: 'payPoint',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Gateway',
        align: 'start',
        sortable: false,
        value: 'provider',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Transaction #',
        align: 'start',
        sortable: false,
        value: 'transactionId',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    total: 0,
    companyList: [],
    accountUuid: '',
    userEmail: '',
    filter: {
      startDate: '',
      endDate: ''
    }
  }),
  computed: {
    countPage () {
      return Math.ceil(this.total / this.itemsPerPage)
    }
  },
  watch: {
    page () {
      this.loadPayments()
    },
    accountUuid () {
      this.page = 1
      this.loadPayments()
    },
    userEmail () {
      this.page = 1
      this.loadPayments()
    },
    filter: {
      deep: true,
      handler () {
        this.page = 1
        this.loadPayments()
      }
    }
  },
  created () {
    if (this.$route.params.companyUuid) this.accountUuid = this.$route.params.companyUuid
    this.loadPayments()
    this.loadCompany()
  },
  methods: {
    ...mapActions('admin', ['getAdminPayments', 'getAdminCompany']),
    async loadPayments (forExport = false) {
      try {
        this.loading = true
        const params = {
          offset: forExport ? 0 : (this.page - 1) * this.itemsPerPage,
          limit: forExport ? 9999 : this.itemsPerPage,
          orderType: 'desc'
        }

        if (this.accountUuid) {
          params.filterBy = 'accountUuid'
          params.filterValue = this.accountUuid
        }
        if (this.userEmail) {
          params.filterBy = 'userEmail'
          params.filterValue = this.userEmail
        }

        Object.entries(this.filter).forEach(([key, value]) => {
          if (value) params[key] = value
        })

        const { data } = await this.getAdminPayments(params)
        if (!data) return

        const items = data.list.map(item => ({
          ...item.payment,
          payPoint: item.payment.payPoint.replace(/_([a-z])/g, function (g) { return ' ' + g[1].toUpperCase() }),
          stripeTransaction: item.stripeTransaction,
          accountName: item.payment.account.accountName
        }))

        if (forExport) return items

        this.items = items
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async loadCompany () {
      try {
        const params = {
          limit: 9999
        }
        const { data } = await this.getAdminCompany(params)
        if (!data) return
        const companyList = data.data.sort((a, b) => {
          if (a.accountName.toLowerCase() < b.accountName.toLowerCase()) {
            return -1
          }
          if (a.accountName.toLowerCase() > b.accountName.toLowerCase()) {
            return 1
          }
          return 0
        })
        this.companyList = [
          {
            accountName: 'Not selected',
            accountUuid: ''
          },
          ...companyList
        ]
      } catch (err) {
        throw new Error(err)
      }
    },
    changeStartDate (value) {
      if (new Date(value) > new Date(this.filter.endDate)) {
        this.filter.startDate = `${this.filter.endDate}`
        this.filter.endDate = value
      } else this.filter.startDate = value
    },
    changeEndDate (value) {
      if (new Date(value) < new Date(this.filter.startDate)) {
        this.filter.endDate = `${this.filter.startDate}`
        this.filter.startDate = value
      } else this.filter.endDate = value
    },
    async exportCurrentView () {
      const csvArray = []
      let values = []
      const self = this

      this.headers.forEach(function (item, i) {
        if (item.value !== 'action') {
          values.push(item.text)
        }
      })
      csvArray.push(values)

      const items = await this.loadPayments(true)
      items.forEach(function (item, i) {
        values = []
        self.headers.forEach(function (it, i) {
          if (it.value !== 'action') {
            if (Object.keys(it).includes('formatter')) {
              const val = self.formatDate(item[it.value])
              values.push(val)
            } else {
              values.push(item[it.value])
            }
          }
        })
        csvArray.push(values)
      })

      exportToCsv('Payments.csv', csvArray)
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>
