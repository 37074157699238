<template>
  <v-card
    :loading="loading"
    class="ma-auto pa-md-10 pa-0 h-100-mobile w-100"
    max-width="660px"
  >
    <div class="header d-flex d-md-none align-center justify-center w-100 px-5 py-7" style="background-color: #00084B">
      <v-img
        position="left center"
        :src="require('@/assets/logo.svg')"
        height="31px"
        max-width="120px"
        contain
      ></v-img>
    </div>
    <v-card-title class="pa-0 pa-4 px-md-0 pb-8">
      <h2
        :class="isDesktop ? 'font-weight-semi-bold fz-28 line-h-32' : 'font-weight-medium fz-24 line-h-29'"
        class="dark-text"
      >Sign Up</h2>
    </v-card-title>

    <v-card-text :class="{ 'mobile-registration': !isDesktop }" class="pa-0 px-4 px-md-0" >
      <v-form v-model="formValidate" lazy-validation ref="form" class="h-100-mobile" autocomplete="off">
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block required">First name</label>
            <v-text-field
              class="text-input"
              solo
              outlined
              type="text"
              v-model="form.firstName"
              :rules="nameRules"
              dense
              @keypress="onlyLetters"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block required">Last name</label>
            <v-text-field
              solo
              outlined
              type="text"
              v-model="form.lastName"
              :rules="lastnameRules"
              dense
              class="text-input"
              @keypress="onlyLetters"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block required">Email</label>
            <v-text-field
              class="text-input"
              autocomplete="new-email"
              persistent-placeholder
              solo
              outlined
              type="email"
              v-model="form.email"
              :rules="emailRules"
              :name="'user_e' + Math.random()"
              dense
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block">Phone number</label>
            <v-text-field
              v-model="phoneNumber"
              :rules="form.userPhone ? phoneRules : []"
              :maxlength="17"
              class="text-input"
              solo
              outlined
              type="tel"
              dense
              @keypress="IsNumber"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block required">Password</label>
            <v-text-field
              v-model="form.password"
              :rules="passwordRules"
              :name="'user_p' + Math.random()"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              class="text-input"
              autocomplete="new-password"
              persistent-placeholder
              solo
              outlined
              dense
              required
              @click:append="showPass = !showPass"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block required">Re-enter password</label>
            <v-text-field
              v-model="form.rePassword"
              :rules="passwordRules"
              :append-icon="showRePass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showRePass ? 'text' : 'password'"
              ref="rePassword"
              class="text-input"
              solo
              outlined
              dense
              required
              @click:append="showRePass = !showRePass"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-n13">
          <v-col
            cols="12"
            md="12"
            class="pt-0 pb-6 d-none d-md-block"
          >
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block">Company Name</label>
            <v-text-field
              class="text-input"
              solo
              outlined
              type="text"
              v-model="form.companyName"
              dense
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block required">Subdomain</label>
            <v-text-field
              type="text"
              v-model="form.companySlug"
              solo
              outlined
              hide-details
              @keypress="onlyLetters"
            >
              <template v-slot:append>
                {{ domain }}
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-7 mb-xs-7 mb-sm-7 align-baseline" :class="!isDesktop ? 'no-gutters' : ''">
        <v-col
          order-md="1"
          order="2"
            cols="12"
            md="6"
            class="d-flex align-center justify-center justify-md-start regular-text--text px-0"
          >
          <span style="white-space: pre">Already have an account?</span>
          <v-btn
            to="/login"
            :color="!isDesktop ? 'primary' : ''"
            :text="!isDesktop"
            class="ml-3"
            style="text-transform: capitalize;"
            :class="!isDesktop ? 'font-weight-bold' : 'grey-button regular-text--text'"
          >
            Sign In
          </v-btn>
          </v-col>

          <v-col
            order-md="2"
            order="1"
            cols="12"
            md="6"
            class="d-flex flex-row justify-end pb-0"
          >
            <v-btn
              :class="!isDesktop ? 'w-100' : ''"
              :disabled="disableSubmit"
              class="violet-button py-3 px-6"
              height="44px"
              @click="userRegistration"
            >Sign Up
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <vue-snotify/>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Registration',
  data: () => ({
    loading: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      userPhone: '',
      password: '',
      rePassword: '',
      companyName: '',
      companySlug: ''
    },
    formValidate: false,
    nameRules: [
      v => !!v || 'First name is required'
    ],
    lastnameRules: [
      v => !!v || 'Last name is required'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /[a-z0-9._-]+@[a-z0-9.-]+(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    showPass: false,
    showRePass: false,
    isFree: true
  }),
  computed: {
    passwordRules () {
      return [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'Password must be at least 8 characters',
        v => v.length <= 20 || 'Password must be less than 20 characters',
        v => /(?=.*[!@#$%^&/+\-*.|])/.test(v) || 'Password must contain at least one special character',
        v => /(?=.*[0-9])/.test(v) || 'Password must contain at least one numeric character',
        v => /(?=.*?[A-Z])/.test(v) || 'Password must contain at least one uppercase alphabetical character',
        v => /(?=.*?[a-z])/.test(v) || 'Password must contain at least one lowercase alphabetical character',
        v => v === this.form.password || 'Passwords does not match'
      ]
    },
    phoneRules () {
      return [
        // v => v.length >= 11 || 'Phone number must be at least 11 characters',
        // eslint-disable-next-line no-useless-escape
        v => /^[\+]?[0-9]+$/.test(v.replace(/\D/g, '')) || 'Phone number must be valid'
      ]
    },
    disableSubmit () {
      const notRec = ['companyName', 'userPhone']
      return Object.keys(this.form).some(e => !notRec.includes(e) && !this.form[e]) || !this.formValidate
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    domain () {
      // return `.${window.location.hostname}`
      return '.fonio.app'
    },
    phoneNumber: {
      get () {
        if (!this.form.userPhone) return ''
        try {
          const cleaned = `${this.form.userPhone}`
          const match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/)
          return [
            match[1] ? '+1 ' : '',
            match[2] ? '(' : '',
            match[2],
            match[3] ? ') ' : '',
            match[3],
            match[4] ? '-' : '',
            match[4]
          ].join('')
        } catch (err) {
          return `(${this.form.userPhone.slice(0, 3)}) ${this.form.userPhone.slice(3, 6)}-${this.form.userPhone.slice(6)}`
        }
      },
      set (e) {
        this.form.userPhone = e.replace(/\D/g, '')
      }
    }
  },
  watch: {
    'form.password' () {
      this.$refs.rePassword.validate()
    }
  },
  created () {
    if (this.$route.query.paid) this.isFree = false
  },
  methods: {
    ...mapActions(['registration', 'isSubdomainAvailable']),
    async userRegistration () {
      if (!this.formValidate) return
      this.$snotify.notifications = []
      try {
        const isSlugAvailable = await this.checkSlug()

        if (!isSlugAvailable) return this.$snotify.error('This subdomain has been taken. Please enter another one.')

        const { data } = await this.registration({
          form: {
            ...this.form,
            is_free: this.isFree
          }
        })
        if (!data) return
        this.$snotify.notifications = []
        const options = {
          timeout: 0,
          buttons: [
            {
              text: 'Back to sign in',
              action: (toast) => {
                this.$router.push({ name: 'Login' })
                this.$snotify.remove(toast.id)
              }
            }
          ]
        }
        this.$snotify.success('Confirmation message was sent to your email address!', options)
        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 3000)
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
      } finally {
        this.loading = false
      }
    },
    async checkSlug () {
      try {
        const { data } = await this.isSubdomainAvailable(this.form.companySlug)
        return data.isAvailable
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
      }
    },
    onlyLetters (event) {
      if (!/^[A-Za-z\d]+$/.test(event.key)) return event.preventDefault()
    }
  }
}
</script>

<style scoped>
.title {
  line-height: 29px;
  font-size: 24px !important;
}

.mobile-registration ::v-deep .text-input .v-input__slot .v-text-field__slot{
  height: 48px;
}

.text-input ::v-deep .v-text-field__slot input::placeholder {
  color: #000 !important;
}

button[disabled] {
  background: gray !important;
  border-color: gray !important;
  outline: none !important;
}
</style>
