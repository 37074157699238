<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="itemsPerPage"
          item-key="id"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.createdOn`]="{ item }">
            {{ formatDateOnly(item.createdOn) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleStatus(item)"
                  >
                    <v-icon color="#919EAB">{{ item.user_active ? 'toggle_on' : 'toggle_off' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.user_active ? 'Deactivate' : 'Activate' }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-divider/>
        <div v-if="total > itemsPerPage" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex flex-wrap h-max">
            <template v-for="index in countPage">
              <v-divider
                v-if="index"
                :key="index + 'v-divider--page'"
                vertical
                class="pa-0"
              />
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex" style="min-width: 73px;">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <vue-snotify/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'Users',
  data: () => ({
    loading: false,
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: 'Created On',
        align: 'start',
        sortable: false,
        value: 'created_on',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: false,
        value: 'user_email',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Role',
        align: 'start',
        sortable: false,
        value: 'role',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    total: 0
  }),
  computed: {
    countPage () {
      return Math.ceil(this.total / this.itemsPerPage)
    }
  },
  watch: {
    page () {
      this.loadUsers()
    }
  },
  created () {
    this.loadUsers()
  },
  methods: {
    ...mapActions('admin', ['getAdminCompanyUsers', 'changeAdminUserStatus']),
    async loadUsers () {
      try {
        if (!this.$route.params.companyUuid) return
        this.loading = true
        const params = {
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          orderType: 'desc'
        }
        const { data } = await this.getAdminCompanyUsers({ accountUuid: this.$route.params.companyUuid, params })
        if (!data) return
        this.items = data.data
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async toggleStatus (item) {
      try {
        const { data } = await this.changeAdminUserStatus({ uuid: item.user_uuid, status: !item.user_active })
        if (!data) return
        this.$snotify.notifications = []
        this.$snotify.success('Successful')
        item.user_active = !item.user_active
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      }
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>
