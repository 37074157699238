
export default function (filename, rows, textHeader = null, textFooter = null, delimiter = ',') {
  var processRow = function (row) {
    var finalVal = ''
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : (row[j] === undefined ? '' : row[j].toString())
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString()
      }
      var result = innerValue.replace(/"/g, '""')
      if (result.search(/("|,|\n)/g) >= 0) { result = '"' + result + '"' }
      if (j > 0) { finalVal += delimiter }
      finalVal += result
    }
    return finalVal + '\n'
  }

  var csvFile = textHeader !== null ? textHeader + '\n' : ''

  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i])
  }
  csvFile += textFooter !== null ? textFooter : ''

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    var link = document.createElement('a')
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
