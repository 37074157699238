<template>
  <div class="pt-4 pb-2">
    <router-link
      to="/contacts"
      class="back-link"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      Contacts
    </router-link>

    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4">Import contacts</h1>

    <v-card>
      <v-data-table
        v-ripple="false"
        v-model="selected"
        :headers="headers"
        :items="contacts"
        item-key="firstname"
        show-select
        hide-default-footer
        class="v-data-table--custom pt-2"
        :item-class="itemTextColor"
        :header-props="{sortIcon: 'arrow_drop_down'}"
      >
        <template v-slot:[`header.data-table-select`]="{ props, on }">
          <v-simple-checkbox
            :value="props.value || props.indeterminate"
            v-on="on"
            on-icon="check"
            indeterminate-icon="remove"
            off-icon=""
            :indeterminate="props.indeterminate"
            color="primary"
          />
        </template>

        <template v-slot:item.data-table-select="{ isSelected, select }">
          <v-simple-checkbox
            v-ripple="{ 'center': true }"
            :value="isSelected"
            @input="select($event)"
            on-icon="check"
            indeterminate-icon="remove"
            off-icon=""
          />
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            small
            :class="{
              'success-lighten': item.status === 'success',
              'red-lighten': item.status === 'duplicate',
              'orange-lighten': item.status === 'email'
            }"
            class="text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <v-col
        cols="12"
        md="12"
        class="d-flex justify-end align-center px-5 pb-5 pt-6"
      >
        <v-btn to="/contacts" class="grey-button">Cancel</v-btn>
        <v-btn
          v-if="selected.length"
          @click="importContacts"
          class="grey-button ml-4"
        >Import</v-btn>

        <v-btn
          class="violet-button ml-4"
          @click="importContacts"
        >
          Import all
        </v-btn>
      </v-col>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ImportedContacts',
  data: () => ({
    headers: [
      {
        text: 'First name',
        align: 'start',
        sortable: true,
        value: 'firstname'
      },
      {
        text: 'Last name',
        align: 'start',
        sortable: true,
        value: 'lastname'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Phone Number',
        align: 'start',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'status'
      }
    ],
    contacts: [
      {
        firstname: 'Lora',
        lastname: 'Patterson',
        email: 'lester.emard@von.org',
        phone: '410-573-3221',
        status: 'success'
      },
      {
        firstname: 'Estelle',
        lastname: 'Horton',
        email: 'jadon_bahringer@ollie.com',
        phone: '005-483-6329',
        status: 'success'
      },
      {
        firstname: 'Dean',
        lastname: 'Bates',
        email: 'eliza.rodriguez@runolfsdottir.io',
        phone: '005-483-6329',
        status: 'success'
      },
      {
        firstname: 'Effie',
        lastname: 'Johnson',
        email: 'flatley.gust@ulices.us',
        phone: '389-799-6916',
        status: 'duplicate'
      },
      {
        firstname: 'Jesse',
        lastname: 'Guerrero',
        email: 'forest.boyle@maverick.org',
        phone: '163-492-9881',
        status: 'email'
      },
      {
        firstname: 'Gene',
        lastname: 'Hernandez',
        email: 'schamberger@yahoo.com',
        phone: '500-486-2062',
        status: 'success'
      }
    ],
    selected: []
  }),
  methods: {
    importContacts () {
      console.log(this.selected)
    },
    itemTextColor (item) {
      if (item.status === 'success') return 'success-lighten-color'
      if (item.status === 'email') return 'orange-lighten-color'
      if (item.status === 'duplicate') return 'red-lighten-color'
    }
  }
}
</script>
<style scoped lang="scss">
  .success-lighten {
    background-color: #BBE5B3 !important;
  }
  .red-lighten {
    background-color: #FDAC9A !important;
  }
  .orange-lighten {
    background-color: #FFC48B !important;
  }
  ::v-deep .success-lighten-color td {
    color: #212B36 !important;
  }
  ::v-deep .red-lighten-color td {
    color: #BF0711 !important;
  }
  ::v-deep .orange-lighten-color td {
    color: #C05717 !important;
  }
  ::v-deep .v-chip .v-chip__content {
    font-size: 13px !important;
  }
</style>
