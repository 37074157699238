<template>
  <div class="pt-4 h-100" :class="{ 'bg-white': isMobile }">
    <router-link
      to="/mailboxes"
      class="back-link py-5 px-3 px-md-0"
    >
      <v-icon size="24px" color="#6170C5">
        navigate_before
      </v-icon>
      <div
        :class="isDesktop ? 'font-weight-semi-bold fz-28 line-h-32' : 'fz-20 line-h-23'"
        class="dark-text pl-0 px-md-0"
      >
        {{ $route.name === 'CreateMailbox' ? 'Create Mailbox' : 'Edit Mailbox' }}
      </div>
    </router-link>

    <!-- <v-card elevation="0" class="pa-5 mb-5 overflow-hidden" :loading="loading">
      <v-form v-model="valid">
        <v-row class="mx-0">
          <v-col
            cols="12"
            :md="$store.state.auth.currentUser.role === 'account_admin' ? 'auto' : 3"
            class="px-0 pb-3"
          >
            <label class="fz-14 mb-1 d-block">Type</label>
            <v-select
              v-model="form.isShared"
              :items="typeItems"
              solo
              outlined
              dense
              menu-props="offsetY"
              item-value="value"
              item-text="name"
              hide-details
              label="Type"
              class="pa-0"
              style="width: 150px"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            :md="$store.state.auth.currentUser.role === 'account_admin' ? 5 : 9"
            class="px-0 pl-md-3 pb-3"
          >
            <label class="fz-14 mb-1 d-block">Title</label>
            <v-text-field
              :maxlength="50"
              solo
              outlined
              type="text"
              v-model="form.title"
              dense
              hide-details
              @keypress="onlyAlphanumeric"
            ></v-text-field>
          </v-col> -->

          <!-- <v-col
            v-if="$store.state.auth.currentUser.role === 'account_admin'"
            cols="12"
            lg="3"
            md="6"
            class="px-0 pl-lg-3 pb-3"
          >
            <label class="fz-14 mb-1 d-block">Phone number</label>
            <v-select
              v-model="form.phone"
              :items="phoneList"
              menu-props="offsetY"
              solo
              outlined
              hide-details
              dense
              label="Phone number"
              class="pa-0"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col> -->

          <!-- <v-col
            cols="12"
            :lg="$store.state.auth.currentUser.role === 'company_admin' ? 2 : 3"
            md="6"
            class="px-0 pl-md-3 pb-3"
          >
            <label class="fz-14 mb-1 d-block">Extension</label>
            <v-text-field
              label="Extension"
              type="text"
              v-model="form.extension"
              solo
              outlined
              hide-details
              dense
            >
              <template v-slot:prepend-inner>
                ×
              </template>
            </v-text-field>
          </v-col> -->
        <!-- </v-row> -->

        <!-- <v-row
          class="mx-0"
          v-if="$store.state.auth.currentUser.role === 'account_admin'"
        >
          <v-col
            cols="12"
            md="12"
            class="pt-0"
          >
            <v-divider class="hl-724"></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="px-0 pt-0 d-flex align-center fz-14"
          >
            <v-checkbox
              v-model="form.checkbox.playPosition.flag"
              class="checkbox fz-14 ma-0 mr-3 pa-0"
            >
            </v-checkbox>

            Play Position of caller in queue every
            <v-text-field
              v-model="form.checkbox.playPosition.minutes"
              class="pa-0 mx-2 v-input--number"
              style="max-width: 60px;"
              hide-details
              solo
              outlined
              dense
              type="number"
              :disabled="!form.checkbox.playPosition.flag"
            >
              <template v-slot:append>
                <div class="d-flex flex-column mr-n3">
                  <v-btn
                    class="v-input--button pa-0 bt-rr-3"
                    min-width="22px"
                    height="18px"
                    @click.stop="increaseNumber"
                  >
                    <v-icon size="15px" color="#656C74">arrow_drop_up</v-icon>
                  </v-btn>
                  <v-btn
                    class="v-input--button pa-0 bb-rr-3"
                    min-width="22px"
                    height="18px"
                    @click="decreaseNumber"
                  >
                    <v-icon size="15px" color="#656C74">arrow_drop_down</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-text-field>
            minutes
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="px-0"
          >
            <v-checkbox
              v-model="form.checkbox.playConnection"
              class="checkbox fz-14 ma-0 pa-0"
            >
              <template v-slot:label>
                Play "Connecting" prompt to caller before caller is to be connected
              </template>
            </v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="px-0 pb-0"
          >
            <v-checkbox
              v-model="form.checkbox.playCallback"
              class="checkbox fz-14 ma-0 pa-0"
            >
              <template v-slot:label>
                Play "Callback Prompt"
              </template>
            </v-checkbox>

            <p
              class="fz-12 pl-7"
              style="color: #637381;"
            >
              Let user to choose if they want to hangup now and let us call them back when we are free.
            </p>
          </v-col>
        </v-row> -->

        <!-- <v-row v-if="!form.isShared">
          <v-col
            cols="12"
            md="12"
            class="pb-5 pt-0"
          >
            <v-divider class="hl-724"></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pt-0"
            style="position:relative;"
          >
            <div
              :class="isMobile ? 'font-weight-medium text-capitalize' : 'font-weight-semi-bold fz-12 line-h-16 text-uppercase'"
              class="mb-1"
            >Mailbox access</div>

            <SearchDropdown
              :mailboxUuid="$route.params.uuid"
              @updateUsers="updateSearchUsers"
              @addNewUser="addUserSearch"
              @removeUser="removeSearchUser"
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="py-0"
            v-if="form.users.length"
          >
            <v-list class="py-0">
              <template v-for="(user, userIndex) in form.users">
                <v-divider :key="userIndex"></v-divider>
                <v-list-item :key="userIndex + 'msg'" class="px-0">
                  <v-list-item-avatar>
                    <v-img
                      :alt="`${user.firstName} avatar`"
                      :src="user.avatar"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="fz-14 font-weight-medium"
                      style="color: #1D2428;"
                    >
                      {{ fullName(user.firstName, user.lastName) }}
                    </v-list-item-title>
                  </v-list-item-content> -->

                  <!-- <v-list-item-action class="d-flex flex-row align-center">
                    <v-tooltip top content-class="v-tooltip--top">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          @click="removeSearchUser(userIndex)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>

                      </template>
                      <span>Remove user</span>
                    </v-tooltip>
                  </v-list-item-action> -->
                <!-- </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-col v-else style="color:red">Need at least 1 assigned user</v-col>
        </v-row> -->

        <!-- <v-form v-model="validFormUser">
          <v-row v-for="(user, index) in form.newUsers" :key="index">
          <v-col
            cols="12"
            md="12"
            class="py-0"
          >
            <v-divider
              :key="index"
            ></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="3"
            class="pr-0"
          >
            <label class="fz-14 mb-1 d-block">
              Full name
            </label>
            <v-text-field
              label="Full name"
              type="text"
              v-model="user.name"
              :rules="nameRules"
              solo
              outlined
              hide-details
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pr-0 pl-5"
          >
            <label class="fz-14 mb-1 d-block">Work email</label>
            <v-text-field
              label="Work"
              type="text"
              v-model="user.workEmail"
              :rules="emailRules"
              solo
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pl-5 pr-0"
          >
            <label class="fz-14 mb-1 d-block">Access</label>
            <v-select
              label="Access"
              v-model="user.access"
              :items="role"
              menu-props="offsetY"
              :rules="nameRules"
              class="pa-0"
              solo
              outlined
              dense
              hide-details
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="1"
            class="align-self-end"
          >
            <v-tooltip top content-class="v-tooltip--top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="removeNewUser(index)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span>Clear</span>
            </v-tooltip>
          </v-col>
        </v-row>
        </v-form> -->
      <!-- </v-form>
    </v-card> -->

    <create-mailbox-component
      ref="createMailbox"
      @changed="e => changed = e"
      @isDisable="e => isDisable = e"
    />

    <header-buttons
      v-if="isDesktop"
      :ok="$route.name === 'EditMailbox' ? 'Save' : 'Create'"
      @ok="create"
      :changed="changed"
      :disabled="isDisable"
    />
    <footer-buttons
      v-else
      :disabled="isDisable"
      :ok="$route.name === 'EditMailbox' ? 'Save' : 'Create'"
      class="ma-4"
      @ok="create"
    />
    <vue-snotify/>
  </div>
</template>

<script>
import CreateMailboxComponent from '@/components/CreateMailboxComponent'
import HeaderButtons from '@/components/HeaderButtons'
import FooterButtons from '@/components/FooterButtons'
import { mapActions } from 'vuex'

export default {
  name: 'CreateMailbox',
  components: { HeaderButtons, CreateMailboxComponent, FooterButtons },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    isChange: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    isDisable: true,
    changed: false
    /* loading: false,
    isFetching: false,
    oldName: '',
    typeItems: [
      {
        value: false,
        name: 'Private'
      },

      {
        value: true,
        name: 'Public'
      }
    ],
    nameRules: [
      v => !!v || 'Date is required'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /[a-z0-9]+@[a-z]+(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    valid: false,
    validFormUser: false,
    form: {
      title: '',
      isShared: null,
      phone: '311-171-8252',
      extension: '8721',
      users: [],
      checkbox: {
        playPosition: {
          flag: false,
          minutes: 3
        },
        playConnection: false,
        playCallback: false
      },
      newUsers: [
        {
          name: '',
          workEmail: '',
          access: undefined
        }
      ]
    },
    role: ['Listen', 'Listen & Download', 'Download'],
    phoneList: ['311-171-8252', '311-171-0000', '311-111-8255'] */
  }),
  /* created () {
    if (this.$route.params.uuid) {
      this.loadMailbox()
    } else {
      this.form.isShared = false
    }
  }, */
  computed: {
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  /* watch: {
    async 'form.isShared' (isShared) {
      if (!this.$route.params.uuid || isShared) return
      await this.editMailbox({
        uuid: this.$route.params.uuid,
        payload: { isShared }
      })
    }
  }, */
  methods: {
    create () {
      this.$refs.createMailbox.create()
    }
    /* ...mapActions([
      'createMailbox',
      'getMailbox',
      'editMailbox',
      'addUserToMailbox',
      'deleteUserFromMailbox'
    ]),
    async loadMailbox () {
      try {
        this.loading = true
        const { data } = await this.getMailbox(this.$route.params.uuid)

        this.oldName = this.form.title = data.mailbox.name
        this.form.isShared = data.mailbox.isShared
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    increaseNumber () {
      this.form.checkbox.playPosition.minutes++
    },
    decreaseNumber () {
      if (this.form.checkbox.playPosition.minutes !== 0) this.form.checkbox.playPosition.minutes--
    },
    removeNewUser (index) {
      if (index) {
        this.form.newUsers.splice(index, 1)
      } else {
        this.form.newUsers[index].access = undefined
        this.form.newUsers[index].name = ''
        this.form.newUsers[index].workEmail = ''
      }
    },
    updateSearchUsers (users) {
      this.form.users = users
    },
    async removeSearchUser (uuid) {
      try {
        this.form.users = this.form.users.filter(e => e.uuid !== uuid)

        if (this.$route.name !== 'EditMailbox' || !uuid) return

        const payload = {
          mailboxUuid: this.$route.params.uuid,
          userUuid: uuid
        }
        const { data } = await this.deleteUserFromMailbox(payload)
        if (!data) return

        this.$snotify.notifications = []
        this.$snotify.success('User removed successfully')
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    async addUserSearch (item) {
      try {
        if (this.form.users.findIndex(e => e.uuid === item.uuid) === -1) {
          this.form.users.push(item)
        }
        if (this.$route.name !== 'EditMailbox' || !item || !item.uuid) return

        const payload = {
          mailboxUuid: this.$route.params.uuid,
          userUuid: item.uuid
        }
        const { data } = await this.addUserToMailbox(payload)

        this.$snotify.notifications = []
        this.$snotify.success('User added successfully')
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    },
    async create () {
      if (this.isFetching) return
      this.isFetching = true
      if (this.$route.name === 'EditMailbox') {
        this.edit()
        return
      }
      try {
        const payload = {
          mailboxName: this.form.title,
          isShared: this.form.isShared
        }
        if (!this.form.isShared) payload.usersWithAccess = this.form.users.map(item => item.uuid)

        const { data } = await this.createMailbox(payload)
        if (!data) return
        this.$snotify.notifications = []
        this.$snotify.success('Successful')
        setTimeout(e => {
          this.$router.push({ name: 'Mailboxes' })
        }, 3000)
      } catch (err) {
        let message = Array.isArray(err.message) ? err.message[0] : []
        if (message === 'usersWithAccess must contain no more than 10 elements') {
          message = 'Users with access must contain not more than 10 elements'
        } else if (message.includes('mailboxName must be shorter')) {
          message = 'Mailbox name be shorter than or equal to 30 characters'
        }
        this.$snotify.error(message)
        throw new Error(err)
      } finally {
        setTimeout(e => {
          this.isFetching = false
        }, 3200)
      }
    },
    async edit () {
      try {
        const payload = {
          isShared: this.form.isShared
        }
        if (this.changed) payload.mailboxName = this.form.title
        await this.editMailbox({
          uuid: this.$route.params.uuid,
          payload
        })
        this.$snotify.notifications = []
        this.$snotify.success('Successful')
        setTimeout(e => {
          this.$router.push({ name: 'Mailboxes' })
        }, 3000)
      } catch (err) {
        let message = Array.isArray(err.message) ? err.message[0] : []
        if (message === 'usersWithAccess must contain no more than 10 elements') {
          message = 'Users with access must contain not more than 10 elements'
        } else if (message.includes('mailboxName must be shorter')) {
          message = 'Mailbox name be shorter than or equal to 30 characters'
        }
        this.$snotify.error(message)
        throw new Error(err)
      } finally {
        setTimeout(e => {
          this.isFetching = false
        }, 3200)
      }
    } */
  }
}
</script>
