<template>
  <v-card
    :class="isExists ? 'pa-0' : 'pa-10'"
    class="ma-auto overflow-hidden"
    max-width="660px"
  >
    <v-card-title v-if="!isExists" class="pa-0 pb-8">
      <h2 class="font-weight-semi-bold fz-28 dark-text">Invitation</h2>
    </v-card-title>

    <v-card-text v-if="!isExists" class="pa-0">
      <v-form v-model="formValidate" lazy-validation>
        <v-row class="mt-0">
<!--          <v-col-->
<!--            cols="12"-->
<!--            md="6"-->
<!--            class="py-0"-->
<!--          >-->
<!--            <label class="fz-14 mb-1 d-block">Email</label>-->
<!--            <v-text-field-->
<!--              solo-->
<!--              outlined-->
<!--              label="Email"-->
<!--              type="email"-->
<!--              v-model="form.email"-->
<!--              :rules="emailRules"-->
<!--              dense-->
<!--            ></v-text-field>-->
<!--          </v-col>-->

          <v-col
            cols="12"
            md="12"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block">Password</label>
            <v-text-field
              solo
              outlined
              label="Password"
              type="password"
              v-model="form.password"
              :rules="passwordRules"
              dense
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="py-0"
          >
            <label class="fz-14 mb-1 d-block">Confirm password</label>
            <v-text-field
              solo
              outlined
              label="Confirm"
              type="password"
              v-model="form.rePassword"
              :rules="passwordRules"
              dense
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="py-0"
          ></v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          ></v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-end"
          >
            <v-btn
              :disabled="!form.password || !formValidate"
              class="violet-button"
              height="44px"
              @click="submit"
            >Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <vue-snotify/>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Registration',
  data: () => ({
    loading: false,
    form: {
      email: '',
      password: '',
      rePassword: '',
      invitationUuid: ''
    },
    formValidate: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(v) || 'E-mail must be valid'
    ]
  }),
  computed: {
    passwordRules () {
      return [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'Password must be at least 8 characters',
        v => v.length <= 20 || 'Password must be less than 20 characters',
        v => /(?=.*[!@#$%^&.*])/.test(v) || 'Password must contain at least one special character',
        v => /(?=.*[0-9])/.test(v) || 'Password must contain at least one numeric character',
        v => /(?=.*?[A-Z])/.test(v) || 'Password must contain at least one uppercase alphabetical character',
        v => /(?=.*?[a-z])/.test(v) || 'Password must contain at least one lowercase alphabetical character',
        v => v === this.form.password || 'Passwords does not match'
      ]
    },
    invitationUuid () {
      return this.$route.query.invitationUuid
    },
    isExists () {
      return this.$route.query.isExists === 'true'
    }
  },
  mounted () {
    if (this.isExists) this.submit()
  },
  methods: {
    ...mapActions(['acceptInvitation']),
    async submit () {
      this.$snotify.notifications = []
      if (!this.isExists && !this.formValidate) return

      try {
        this.loading = true
        const data = this.isExists ? {} : {
          password: this.form.password,
          rePassword: this.form.rePassword
        }
        await this.acceptInvitation({ invitationUuid: this.invitationUuid, data })
        this.$snotify.success('Account has been created successfully')
        setTimeout(e => {
          this.$router.push({ name: 'Login' })
        }, 2000)
      } catch (err) {
        this.$snotify.error(err.message)
        if (this.isExists) {
          setTimeout(e => {
            this.$router.push({ name: 'Login' })
          }, 2000)
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
